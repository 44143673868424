define("innowaze-ember2/components/modules/teammates-module", ["exports", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/components/modules/module"], function (_exports, _selectAutocomplete, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend(_selectAutocomplete.default, {
    completedDidChange: Ember.on("init", Ember.observer("model.teammates.[]", function () {
      this.set("module.isNotCompleted", this.get("model.teammates").length === 0);
    })),
    isAuthor: Ember.computed("session.currentUser", "model.author", function () {
      return this.get("session.currentUser.id") === this.get("model.author.id");
    }),
    canChangeTeam: Ember.computed("session.isModerator", "session.currentUser.id", "session.isChampion", "model.user_id", function () {
      return this.get("session.isModerator") || this.get("session.isChampion") || this.isAuthor || this.get("model.isNew");
    }),
    manager: Ember.computed("model.author", function () {
      return this.get("model.author") ? this.get("model.author") : null;
    }),
    members: Ember.computed("model.author", function () {
      return this.get("model.author") ? [this.get("model.author")] : [];
    }),
    membersIncludingAuthor: Ember.computed.alias("members"),
    membersExcludingAuthor: Ember.computed("members.[]", "model.author", function () {
      return this.get("model.author") ? this.members.rejectBy("id", this.get("model.author.id")) : this.members;
    }),
    actions: {
      setTeammates: function setTeammates(teammates) {
        this.model.set("teammates", teammates);
      },
      setManager: function setManager(manager) {
        this.model.set("author", manager);
      }
    }
  });

  _exports.default = _default;
});