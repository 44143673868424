define("innowaze-ember2/serializers/idea", ["exports", "ember-data", "innowaze-ember2/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      comments: {
        deserialize: "records",
        serialize: false
      },
      author: {
        deserialize: "records",
        serialize: false
      },
      master: {
        deserialize: "records",
        serialize: false
      },
      owner_scope: {
        deserialize: "records",
        serialize: false
      },
      teammates: {
        deserialize: "records",
        serialize: false
      },
      contributors: {
        deserialize: "records",
        serialize: false
      },
      replicators: {
        deserialize: "records",
        serialize: false
      },
      tags: {
        deserialize: "records",
        serialize: false
      },
      scopes: {
        deserialize: "records",
        serialize: false
      }
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.ideas) {
        return this._super.apply(this, arguments);
      }

      var result = this._super(store, primaryModelClass, payload.ideas, id, requestType);

      result.meta = result.meta || {};
      result.meta.total_results = payload.total_results;
      return result;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.all_states) {
        payload.all_states = payload.all_states.sortBy("position");
      }

      if (payload.purchasing_family) {
        payload.purchasing_family_id = String(payload.purchasing_family.id);
      }

      if (payload.todos) {
        payload.todos = payload.todos.map(function (todo) {
          todo = Ember.Object.create(todo);
          todo.owner = store.findRecord("user", todo.user_id, {
            backgroundReload: false
          }); // fetch actual model from store cache

          return todo;
        });
      }

      if (payload.assignees) {
        payload.assignees = payload.assignees.map(function (assignee) {
          return Ember.Object.create(assignee);
        });
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      delete json.parents;
      delete json.assignees;

      if (snapshot.record.get("tags")) {
        json.tags = snapshot.record.get("tags").map(function (tag) {
          return {
            name: Ember.get(tag, "name")
          };
        });
      }

      if (snapshot.record.get("knowledges")) {
        json.knowledges = snapshot.record.get("knowledges").map(function (knowledge) {
          return {
            id: knowledge.id
          };
        });
      }

      if (snapshot.record.get("domains")) {
        json.domains = snapshot.record.get("domains").map(function (domain) {
          return {
            id: domain.id
          };
        });
      }

      if (snapshot.record.get("author")) {
        json.user_id = snapshot.record.get("author.id");
      }

      delete json.author;

      if (snapshot.record.get("master")) {
        json.master_id = snapshot.record.get("master.id");
      } else {
        json.master_id = null;
      }

      delete json.master;

      if (snapshot.record.get("teammates")) {
        json.teammates = snapshot.record.get("teammates").map(function (teammate) {
          return {
            id: teammate.id
          };
        });
      }

      if (snapshot.record.get("assignees")) {
        json.assignee_ids = snapshot.record.get("assignees").mapBy("user_id");
      }

      if (snapshot.record.get("documents")) {
        json.documents = snapshot.record.get("documents").map(function (document) {
          return {
            id: document.id
          };
        });
      }

      if (snapshot.record.get("extra_modules")) {
        json.extra_modules = snapshot.record.get("extra_modules").filter(function (module) {
          return module.id;
        }).map(function (module) {
          return {
            id: module.id,
            description: module.description
          };
        });
      }

      if (snapshot.record.get("mirror_idea")) {
        json.mirror_idea_id = snapshot.record.get("mirror_idea.id");
        delete json.mirror_idea;
      }

      if (snapshot.record.get("department")) {
        json.department_id = snapshot.record.get("department.id");
        delete json.department;
      }

      if (snapshot.record.get("mirror_target_domain")) {
        json.mirror_target_domain_id = snapshot.record.get("mirror_target_domain.id");
        delete json.mirror_target_domain;
      }

      if (snapshot.record.get("owner_scope")) {
        json.owner_scope_id = snapshot.record.get("owner_scope.id");
        delete json.owner_scope;
      } else {
        json.owner_scope_id = null;
      }

      if (snapshot.record.get("scope_BLs")) {
        json.scope_BLs = snapshot.record.get("scope_BLs").map(function (scope) {
          return {
            id: scope.id
          };
        });
      }

      if (snapshot.record.get("scope_clusters")) {
        json.scope_clusters = snapshot.record.get("scope_clusters").map(function (scope) {
          return {
            id: scope.id
          };
        });
      }

      if (snapshot.record.get("scope_countries")) {
        json.scope_countries = snapshot.record.get("scope_countries").map(function (scope) {
          return {
            id: scope.id
          };
        });
      }

      if (snapshot.record.get("scope_entities")) {
        json.scope_entities = snapshot.record.get("scope_entities").map(function (scope) {
          return {
            id: scope.id
          };
        });
      }

      if (snapshot.record.get("scope_hubs")) {
        json.scope_hubs = snapshot.record.get("scope_hubs").map(function (scope) {
          return {
            id: scope.id
          };
        });
      }

      if (snapshot.record.get("todos")) {
        json.todos_attributes = snapshot.record.get("todos").map(function (todo) {
          return Ember.getProperties(todo, "id", "name", "due_date", "completed", "position", "description", "stage", "user_id", "_destroy");
        });
        delete json.todos;
      }

      return {
        idea: json,
        challenge_id: snapshot.record.get("challenge_id")
      };
    }
  });

  _exports.default = _default;
});