define("innowaze-ember2/components/modules/intellectual-property-module", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "innowaze-ember2/components/modules/module"], function (_exports, _toConsumableArray2, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    completedDidChange: Ember.on("init", Ember.observer("model.intellectual_property_pi_result", function () {
      this.set("module.isNotCompleted", !this.get("model.intellectual_property_pi_result"));
    })),
    availablePIResults: Ember.computed("module.params.pi_results_typology.[]", function () {
      return [{
        id: undefined,
        name: ""
      }].concat((0, _toConsumableArray2.default)(this.get("module.params.pi_results_typology") || []));
    }),
    availablePISoftwares: Ember.computed("module.params.pi_softwares_typology.[]", function () {
      return [{
        id: undefined,
        name: ""
      }].concat((0, _toConsumableArray2.default)(this.get("module.params.pi_softwares_typology") || []));
    }),
    availablePISystems: Ember.computed("module.params.pi_systems_typology.[]", function () {
      return [{
        id: undefined,
        name: ""
      }].concat((0, _toConsumableArray2.default)(this.get("module.params.pi_systems_typology") || []));
    }),
    PIResultName: Ember.computed("model.intellectual_property_pi_result", function () {
      return this.get("model.intellectual_property_pi_result") ? this.availablePIResults.findBy("id", this.get("model.intellectual_property_pi_result").toString()).name : "";
    }),
    PISoftwareName: Ember.computed("model.intellectual_property_pi_software", function () {
      return this.get("model.intellectual_property_pi_software") ? this.availablePISoftwares.findBy("id", this.get("model.intellectual_property_pi_software").toString()).name : "";
    }),
    PISystemName: Ember.computed("model.intellectual_property_pi_system", function () {
      return this.get("model.intellectual_property_pi_system") ? this.availablePISystems.findBy("id", this.get("model.intellectual_property_pi_system").toString()).name : "";
    })
  });

  _exports.default = _default;
});