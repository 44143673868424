define("innowaze-ember2/components/modules/new-scope-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.query("scope", this.get("module.params")).then(function (scopes) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.set("availableScopeCountries", scopes.filterBy("level", "Country")); // For Air Liquide, scopes with parent 2000 (hub : new organization") correspond to all new clusters / GBU / GBS


        _this.set("availableScopeClusters", scopes.filterBy("parent", "2000"));

        _this.set("availableScopeHubs", scopes.filterBy("level", "Hub"));
      });
    },
    showAllScopeClusters: false,
    showAllScopeCountries: false,
    showAllScopeEntities: false,
    completedDidChange: Ember.on("init", Ember.observer("model.scope_clusters.[]", "model.scope_countries.[]", "model.owner_scope", function () {
      var isNotCompleted = this.get("model.scope_clusters").length === 0 || this.get("model.owner_scope") === null;
      this.set("module.isNotCompleted", isNotCompleted);
    })),
    firstScopeClusters: Ember.computed("model.scope_clusters.[]", function () {
      return this.get("model.scope_clusters").slice(0, 10);
    }),
    hasMoreClusters: Ember.computed("model.scope_clusters.[]", function () {
      return this.get("model.scope_clusters").length > 10;
    }),
    firstScopeCountries: Ember.computed("model.scope_countriess.[]", function () {
      return this.get("model.scope_countries").slice(0, 10);
    }),
    firstScopeEntities: Ember.computed("model.scope_entities.[]", function () {
      return this.get("model.scope_entities").slice(0, 10);
    }),
    hasMoreCountries: Ember.computed("model.scope_countries.[]", function () {
      return this.get("model.scope_countries").length > 10;
    }),
    hasMoreEntities: Ember.computed("model.scope_entities.[]", function () {
      return this.get("model.scope_entities").length > 10;
    }),
    actions: {
      clearCountries: function clearCountries() {
        this.model.set("scope_countries", []);
      },
      clearClusters: function clearClusters() {
        this.model.set("scope_clusters", []);
      },
      moreScopeClusters: function moreScopeClusters() {
        this.toggleProperty("showAllScopeClusters");
      },
      moreScopeCountries: function moreScopeCountries() {
        this.toggleProperty("showAllScopeCountries");
      },
      moreScopeEntities: function moreScopeEntities() {
        this.toggleProperty("showAllScopeEntities");
      },
      removeCountry: function removeCountry(country) {
        var e = this.get("model.scope_countries").findBy("id", parseInt(country.id));

        if (e) {
          this.get("model.scope_countries").removeObject(e);
        }
      },
      removeCluster: function removeCluster(cluster) {
        var e = this.get("model.scope_clusters").findBy("id", parseInt(cluster.id));

        if (e) {
          this.get("model.scope_clusters").removeObject(e);
        }
      }
    }
  });

  _exports.default = _default;
});