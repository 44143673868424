define("innowaze-ember2/components/modules/patent-module", ["exports", "innowaze-ember2/components/modules/module"], function (_exports, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    patentProtectedChanged: Ember.on("init", Ember.observer("model.patent_protected", "model.patent_protected_comment", function () {
      var isNotCompleted = this.get("model.patent_protected") && !this.get("model.patent_protected_comment"); // Check model.id to avoid trying to set data on a non-existing model

      if (this.get("model.id") && !this.get("model.patent_protected")) {
        this.model.set("patent_protected_comment", null);
      }

      this.set("module.isNotCompleted", isNotCompleted);
    }))
  });

  _exports.default = _default;
});