define("innowaze-ember2/components/modules/consultation-module", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "innowaze-ember2/components/modules/module"], function (_exports, _toConsumableArray2, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend({
    completedDidChange: Ember.on("init", Ember.observer("model.consultation_da_number", "model.consultation_consulting_type", "model.consultation_optional_segment", "model.consultation_following", function () {
      this.set("module.isNotCompleted", !this.get("model.consultation_da_number") || this.get("model.consultation_consulting_type") === "0" || !this.get("model.consultation_optional_segment") || !this.get("model.consultation_following"));
    })),
    availableConsultingTypes: Ember.computed("module.params.typology", function () {
      return [{
        id: "0",
        name: ""
      }].concat((0, _toConsumableArray2.default)(this.get("module.params.typology")));
    }),
    consultingTypeName: Ember.computed("model.consultation_consulting_type", function () {
      return this.get("model.consultation_consulting_type") ? this.availableConsultingTypes.findBy("id", this.get("model.consultation_consulting_type").toString()).name : "";
    })
  });

  _exports.default = _default;
});