define("innowaze-ember2/components/modules/assignment-module", ["exports", "innowaze-ember2/mixins/routes/selectAutocomplete", "innowaze-ember2/components/modules/module"], function (_exports, _selectAutocomplete, _module) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _module.default.extend(_selectAutocomplete.default, {
    init: function init() {
      this._super.apply(this, arguments);
    },
    members: Ember.computed("model.assignees", function () {
      return this.get("model.assignees").toArray();
    }),
    canEditAssignees: Ember.computed.or("session.isChampion", "session.isAdmin"),
    canSetChoice: Ember.computed("model.assignees", function () {
      var _this = this;

      return this.get("model.assignees").some(function (a) {
        return a.get("user_id") === parseInt(_this.get("session.currentUser.id"));
      });
    }),
    normalizeAssignee: function normalizeAssignee(User) {
      return {
        user_id: parseInt(User.id),
        user_name: User.screenname
      };
    },
    actions: {
      setChoice: function setChoice(assignee, choice) {
        var _this2 = this;

        this.model.setAssignmentChoice(assignee, choice).catch(function () {
          _this2.notifications.error(_this2.locale.t("ideas.modules.assignment.error_saving"));
        }).finally(function () {
          _this2.store.findRecord("idea", _this2.model.id, {
            adapterOptions: {
              challenge_id: _this2.challenge.id
            },
            reload: true
          });
        });
      },
      setMainAssignee: function setMainAssignee(assignee) {
        var _this3 = this;

        this.model.setMainAssignee(assignee).then(function () {
          _this3.store.findRecord("idea", _this3.model.id, {
            adapterOptions: {
              challenge_id: _this3.challenge.id
            },
            reload: true
          });
        }).catch(function () {
          _this3.notifications.error(_this3.locale.t("ideas.modules.assignment.error_saving"));
        });
      },
      commentAdmin: function commentAdmin() {
        this.sendAction("onComment");
      }
    }
  });

  _exports.default = _default;
});