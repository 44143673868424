define("innowaze-ember2/routes/application", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@sentry/browser", "cookieconsent", "fetch", "innowaze-ember2/configuration", "innowaze-ember2/config/environment"], function (_exports, _objectSpread2, Sentry, _cookieconsent, _fetch, _configuration, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-line import/no-unassigned-import
  var _default = Ember.Route.extend({
    locale: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    assetMap: Ember.inject.service(),
    headData: Ember.inject.service(),
    // beforeModel is used because it's the first entry point of the first route.
    // It returns a promise so that the next actions (calling model, setupController, ...)
    // will only be called when promises are resolved.
    // This allow every route after that to have access to currentUser and configuration
    // from this.session and use that information to allow or disallow access.
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return this.store.findRecord("configuration", 1).then(function (config) {
        _this.session.set("configuration", config);

        _this.assetMap.loadThemeStyleSheet(config.get("dict.theme"));

        _this.assetMap.loadFavicon(window.instanceImageFolder);
      });
    },
    afterModel: function afterModel(model, transition) {
      this.setProperties((0, _objectSpread2.default)({
        "headData.title": this.get("session.configuration.dict.instance"),
        "headData.description": this.get("session.configuration.dict.title"),
        "headData.url": document.location.href
      }, this.get("session.configuration.banner_url") && {
        "headData.image": _configuration.default.apiUrl + this.get("session.configuration.banner_url")
      }));
      return this._loadCurrentUser(transition);
    },
    actions: {
      willTransition: function willTransition(transition) {
        var _this2 = this;

        if (this.session.get("currentUser.force_password_update") && transition.targetName !== "users.editPassword") {
          this._redirectToPasswordEdition(transition);

          return;
        }

        this._loadCurrentUser(transition).catch(function (e) {
          if (e && e.message === "unsafe user") {
            _this2._redirectToPasswordEdition(transition);

            return;
          }
        });
      },
      setLocale: function setLocale(language) {
        Cookies.set("locale", language, {
          expires: 30
        });
        this.locale.updateLocale(language);
        var currentChallenge = this.get("session.currentChallenge");

        if (currentChallenge) {
          currentChallenge.reload();

          if (currentChallenge.get("deepl_translation") && currentChallenge.get("translation_coupling")) {
            var lang = this.get("session.currentChallenge").get("deepl_languages").findBy("id", this.get("locale.currentLocale"));

            try {
              this.controller.send("translate", lang.id);
            } catch (err) {
              // Action not found
              console.log("The translate action does not exist !");
              console.log(err);
            }
          }
        }
      },
      error: function error(ref, transition) {
        if (ref && ref.message === "unknown user") {
          transition.abort();

          this._logout(transition.intent.url);

          return;
        }

        if (ref && ref.message === "unsafe user") {
          this._redirectToPasswordEdition(transition);

          return;
        }

        if (ref && ref.errors && ref.errors[0]) {
          switch (ref.errors[0].status) {
            case "404":
              this.transitionTo("/not-found");
              return;

            case "401":
              this._logout();

              return;
          }
        }

        return true;
      },
      logout: function logout() {
        this._logout();
      }
    },
    activate: function activate() {
      this._super();

      $("#app-loader").remove();
    },
    setupController: function setupController(controller, model, transition) {
      var _this3 = this;

      if (_environment.default.environment === "production") {
        (0, _fetch.default)("/version.json").then(function (resp) {
          return resp.json();
        }).then(function (data) {
          return controller.set("frontRevision", data.revision);
        }).catch(console.error);
        (0, _fetch.default)("".concat(_configuration.default.apiUrl, "/version.json")).then(function (resp) {
          return resp.json();
        }).then(function (data) {
          return controller.set("apiRevision", data.revision);
        }).catch(console.error);
      } // Prevent fetching challenges and universes again if they are going to be loaded after transition


      if (!this.get("session.availableChallenges") && transition.targetName !== "challenges.index") {
        this.store.query("universe", {
          published: true
        }).then(function (universes) {
          _this3.session.set("availableUniverses", universes);
        });
        this.store.query("challenge", {
          slim: 1
        }).then(function (challenges) {
          _this3.session.set("availableChallenges", challenges);
        });
      }

      this.store.adapterFor("challenge").findGroups().then(function (groups) {
        _this3.session.set("availableGroups", groups);
      });
      this.store.adapterFor("event").findGroups().then(function (groups) {
        _this3.session.set("availableEventGroups", groups.map(function (g) {
          return Ember.Object.create(g);
        }));

        _this3.session.get("availableEventGroups").setEach("selected", true);
      });
      $(document).ajaxSend(function () {
        Ember.run.next(function () {
          controller.set("nbRequest", controller.nbRequest + 1);
        });
      }).ajaxComplete(function () {
        Ember.run.next(function () {
          controller.set("nbRequest", Math.max(controller.nbRequest - 1, 0));
        });
      });
      this.loadConfiguration(this.session.get("configuration"));
      controller.set("currentPath", transition.targetName);
    },
    loadConfiguration: function loadConfiguration(config) {
      if (config.get("gac")) {
        this._initCookieConsent();
      }

      if ($("meta[name=csrf-token]").length === 0) {
        $("head").append("<meta content=\"".concat(config.get("csrf_tag"), "\" name=\"csrf-param\" />"));
        $("head").append("<meta content=\"".concat(config.get("csrf"), "\" name=\"csrf-token\" />"));
      }
    },
    _initCookieConsent: function _initCookieConsent() {
      var _this4 = this;

      window.cookieconsent.initialise({
        palette: {
          popup: {
            background: "#efefef",
            text: "#404040"
          }
        },
        type: "opt-in",
        content: {
          message: this.locale.t("cookies.message"),
          policy: this.locale.t("cookies.policy"),
          deny: this.locale.t("cookies.deny"),
          allow: this.locale.t("cookies.allow"),
          link: this.locale.t("cookies.link"),
          href: "/pages/tos"
        },
        onInitialise: function onInitialise(status) {
          if (status === "allow") {
            _this4._initAnalitycs();
          }
        },
        onStatusChange: function onStatusChange(status, chosenBefore) {
          if (status === "allow") {
            _this4._initAnalitycs();
          }
        }
      });
    },
    _initAnalitycs: function _initAnalitycs() {
      var head = document.getElementsByTagName("head")[0];
      var myScript = document.createElement("script");
      var session = this.session;
      myScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + session.get("configuration.gac"));

      myScript.onload = function () {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          window.dataLayer.push(arguments);
        }

        gtag("js", new Date());

        if (!window.location.hostname.match(/localhost|front\.openlab\.syn|^192\.168\./)) {
          gtag("config", session.get("configuration.gac"));
        }
      };

      head.insertBefore(myScript, head.children[1]);
    },
    _loadCurrentUser: function _loadCurrentUser(transition) {
      var _this5 = this;

      var isPublicPageSlug = transition.targetName === "pages.show" && _configuration.default.publicPageSlugs.includes(transition.params["pages.show"].slug);

      var isPublicRoute = _configuration.default.subscriptionTargets.includes(transition.targetName) || isPublicPageSlug || _configuration.default.publicInstanceTargets.includes(transition.targetName) && this.session.get("configuration.dict.public_pages");
      return this.store.queryRecord("user", {}).then(function (user) {
        _this5.locale.setInitialLocale(user);

        if (user.id === null) {
          throw new Error("unknown user");
        }

        Sentry.configureScope(function (scope) {
          scope.setUser({
            "email": user.get("email"),
            id: user.id
          });
        });

        _this5.session.set("currentUser", user);

        _this5.session.set("loggedIn", true);

        if (_this5.session.get("currentUser.force_password_update") && transition.targetName !== "users.editPassword" && !isPublicPageSlug) {
          throw new Error("unsafe user");
        }
      }).catch(function (e) {
        _this5.locale.setInitialLocale();

        if (e.message === "unsafe user") {
          throw e;
        }

        if (!isPublicRoute) {
          throw new Error("unknown user");
        }
      });
    },
    _logout: function _logout(deeplink) {
      var _this6 = this;

      this.store.adapterFor("user").logout().then(function () {
        localStorage.removeItem("userEmail");

        if (_environment.default.enableTokenAuth) {
          localStorage.removeItem("userToken");
        }

        if (!deeplink && _this6.session.get("configuration.dict.public_pages")) {
          document.location.href = "/";
          return;
        }

        document.location.href = "/login".concat(deeplink ? "?deeplink=".concat(deeplink) : "");
      });
    },
    _redirectToPasswordEdition: function _redirectToPasswordEdition(transition) {
      this.set("session.previousTransition", transition);
      transition.abort();
      this.transitionTo("users.editPassword", this.session.get("currentUser.id"));
    }
  });

  _exports.default = _default;
});